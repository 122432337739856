<!-- 移动端 他人投资 -->
<template>
  <div id="ChatArea">
    <!-- 表格 -->
    <div class="table">
      <nav class="title">
        <p>{{ $t("trading.yh") }}</p>
        <p>{{ $t("trading.ls") }}</p>
        <p class="ball">{{ $t("trading.jypl") }}</p>
        <p>{{ $t("trading.dljy") }}</p>
        <p>{{ $t("trading.cz") }}</p>
      </nav>
      <ul class="tableCont">
        <li v-for="(item, index) in otherInvesList" :key="index">
          <p class="name">{{ item.username }}</p>
          <p>{{ item.num }}</p>
          <p class="ball">{{ item.show_name }}</p>
          <p style="color: rgb(238, 203, 119)">{{ item.money }}</p>
          <p class="followBtn">
            <button @click="openMask(item)">{{ $t("trading.gd") }}</button>
          </p>
        </li>
      </ul>
    </div>
    <!-- 弹窗 -->
    <div class="mask" v-if="showMask">
      <div class="follow-box">
        <div class="follow-wrapper">
          <p class="title">{{ $t("trading.sfgd") }}?</p>
          <div class="item">
            <span class="label">{{ $t("trading.yh") }}</span
            ><span class="value">{{ currentItem.username }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t("trading.ls") }}</span
            ><span class="value">{{ currentItem.num }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t("trading.jypl") }}</span
            ><span class="value">{{ currentItem.show_name }}</span>
          </div>
          <div class="item cont">
            <span class="label">{{ $t("trading.tznr") }}</span
            ><span class="value">{{ currentItem.show_xize }}</span>
          </div>
          <div class="item">
            <span class="label">{{ $t("trading.jine") }}</span
            ><span class="value" style="color: rgb(214, 162, 62)"
              >{{ currentItem.money }}{{ $t("common.CNY") }}</span
            >
          </div>
          <div class="item btns">
            <button @click="close">{{ $t("common.cancel") }}</button
            ><button @click="cofrim">{{ $t("common.confirm") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { placeOrder } from "@/api/investhome";
import { Toast } from "vant";
export default {
  name: "ChatArea",
  props: {
		isXd: {
			type: Boolean,
			default: true
		},
    otherInvesList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      showMask: false,
      timer: null,
			timerNum: 1000,
      currentItem: {
        money: 0,
        num: "--",
        play_id: 22,
        show_name: "--",
        show_xize: "--",
        username: "xx",
      },
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  beforeDestroy() {
    console.log("销毁了定时器");
    clearInterval(this.timer);
    this.timer = null;
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
		if(this.isXd) {
			this.timerOpen();
		}
  },
  methods: {
    close() {
      this.currentItem = {
        money: 0,
        num: "--",
        play_id: 22,
        show_name: "--",
        show_xize: "--",
        username: "xx",
      };
      this.showMask = false;
    },
    openMask(item) {
      this.currentItem = item;
      this.showMask = true;
    },
    /* 数组处理 */
    arrSplice() {
      let result = this.otherInvesList;
      this.otherInvesList.splice(0, 1); // 删除数组第一条数据
      this.otherInvesList.splice(result.length - 1, 0, result[0]); // 在末尾追加第一条数据
    },
		timerFun() {
			this.timer = setTimeout(() => {
				let rnum = parseInt(Math.random()*3,10) + 1;
				this.timerNum = parseInt(rnum + '000');
				console.log('==========================定时器执行了'+this.timerNum)
			  this.arrSplice();
				this.timerFun();
			}, this.timerNum);
		},
    timerOpen() {
      if (this.timer) clearTimeout(this.timer);
      this.timerFun();
    },
    loadingCom() {
      // 自定义加载图标
      const toast = Toast.loading({
        message: this.$t("common.loading"),
        forbidClick: true,
        loadingType: "spinner",
        duration: 60000,
      });
    },
    cofrim() {
      this.loadingCom();
      this.orderConfirm();
    },
    /* 确认下单 */
    orderConfirm() {
      placeOrder({
        product_id: this.currentItem.product_id,
        hall_id: this.currentItem.hall_id, // 厅id
        play_id: this.currentItem.play_id,
        money: this.currentItem.money,
      }).then((res) => {
        console.log(res);
        Toast.clear();
        Toast.success(this.$t("m_history.tzcg"));
        this.close();
        this.$emit("successBuy");
      });
    },
  },
};
</script>
<style scoped lang="less">
#ChatArea {
  width: 100%;
  padding: 0.2rem 0;

  .table {
    width: 100%;
    background: #373430;
    max-height: 3.5rem;
    overflow: hidden;

    .title,
    li {
      height: 0.7rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }

    .tableCont {
      min-height: 0;
      max-height: 2.8rem;
      overflow: auto;
    }
  }
}

#ChatArea .table .title p,
#ChatArea .table li p {
  font-size: 0.26rem;
  color: #fff;
}

#ChatArea .table .title p:nth-child(1),
#ChatArea .table li p:nth-child(1) {
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#ChatArea .table .title p:nth-child(2),
#ChatArea .table li p:nth-child(2) {
  width: 20%;
}

#ChatArea .table .title p:nth-child(3),
#ChatArea .table li p:nth-child(3) {
  width: 30%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#ChatArea .table .title p:nth-child(4),
#ChatArea .table li p:nth-child(4) {
  width: 15%;
}

#ChatArea .table .title p:nth-child(5),
#ChatArea .table li p:nth-child(5) {
  width: 15%;
}

#ChatArea .table .title .followBtn button,
#ChatArea .table li .followBtn button {
  width: 0.8rem;
  height: 0.34rem;
  background-color: #e2cba7;
  border-radius: 0.17rem;
  color: #38373b;
  font-size: 0.22rem;
  margin: 0;
  padding: 0;
}

#ChatArea .mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;

  .follow-box {
    position: relative;

    .follow-wrapper {
      width: 5.9rem;
      height: 7.57rem;
      background-color: #ffffff;
      border-radius: 0.1rem;
      overflow: hidden;
      margin: 2.4rem auto 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      p {
        color: #333333;
        height: 0.98rem;
        line-height: 0.98rem;
        text-align: center;
        font-size: 0.34rem;
        border-bottom: 1px solid #e5e5e5;
      }

      .item {
        color: #333333;
        display: flex;
        justify-content: space-between;
        height: 0.98rem;
        border-bottom: 1px solid #e5e5e5;
        align-items: center;
        padding: 0 0.6rem;
        font-size: 0.3rem;
      }
      .cont {
        flex: 1;
      }
      .btns {
        border: none;
        padding: 0;
        cursor: pointer;
        button {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          height: 0.98rem;
          border: none;
          text-align: center;
          background-color: #fff;
          font-size: 0.34rem;
          color: #333333;
        }
      }
      .btns button:last-child {
        background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);
        color: #373430;
      }
    }
  }
}
</style>