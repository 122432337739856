<!-- 下单页面 -->
<template>
  <div id="Chat">
    <div class="adddiv">
      <div id="xyrb" class="main">
        <div id="HeaderTop">
          <!-- 顶部导航 -->
          <van-nav-bar
            :left-text="titleTxt"
            left-arrow
            @click-left="onClickLeft"
            @click-right="onClickRight"
          >
            <template #right>
              <i @click="jumpJy" class="iconfont icon-lishijilu"></i>
              <i @click="openTop" class="iconfont icon-caidan"></i>
            </template>
          </van-nav-bar>
          <div class="mask" @click="closeTop" v-if="topShow"></div>
          <ul class="popmenu" v-if="topShow">
            <li class="" @click="jumpOther(1)">
              <i class="iconfont icon-chanpin"></i>
              {{ $t("trading.cpjs") }}
            </li>
            <li class="" @click="jumpOther(2)">
              <i class="iconfont icon-zoushi"></i>
              {{ $t("trading.zst") }}
            </li>
            <li class="Ta">
              <van-switch
                :value="otherIn"
                @input="onInput"
                size="12px"
                active-color="#04BE02"
                inactive-color="#dcdee0"
              />
              {{ $t("trading.trtz") }}
            </li>
            <li class="service" @click="jumpOther(3)">
              <i class="iconfont icon-kefu"></i>
              {{ $t("m_modifyLoginPassword.lxkf") }}
              <!---->
            </li>
          </ul>
        </div>
        <!-- 顶部信息 -->
        <div id="TimeDown">
          <ul class="wrapper">
            <li class="top">
              <div class="balance">
                <p class="moeny">
                  <!-- {{ user.money | privatefg }} {{$t('common.CNY')}} -->
                  {{ user.money | privateMoney }} {{ $t("common.CNY") }}
                  <span
                    class="iconfont icon-shuaxin"
                    @click="updateUser()"
                  ></span>
                </p>
                <p class="name">{{ $t("profile.zhhb") }}</p>
                <div class="tomoney" v-if="show == true">
                  <!-- {{ user.money | privatefg}} -->
                  {{ user.money | privateMoney }}
                </div>
              </div>
              <div class="rechargeBtn">
                <span class="iconfont icon-zhangshangchongzhi"></span>
                {{ $t("m_tabbar.deposit") }}
              </div>
            </li>
            <li class="TimeAndNumber">
              <span>{{ $t("records.lunshu") }}：</span
              ><strong>{{ lunNum }}</strong
              ><span>{{ $t("trading.jiezhi") }}：</span
              ><strong>{{ topMinutes }}:{{ topseconds }}</strong>
            </li>
          </ul>
        </div>
        <!-- 历史轮 -->
        <HistoryLun :historyList="historyList" />
        <!-- 他人投资 -->
        <ChatArea
          v-if="otherIn"
          :isXd="isXd"
          :otherInvesList="otherInvesList"
        />
        <!-- 下单选择 -->
        <BetArea
          v-if="betShow"
          @successBuy="successBuy"
          :conventional="conventional"
          :special="special"
          :digital="digital"
          :hallType="hallType"
          :highLow="highLow"
          :product_id="productId"
          :hall_id="deliteId"
          :edList="edList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  othersInvestment,
  historyMarket,
  palyInfo,
  inRecord,
  placeOrder,
  getEd,
  topData,
} from "@/api/investhome";
import HistoryLun from "@/components/mc/HistoryLun";
import ChatArea from "@/components/mc/ChatArea";
import BetArea from "@/components/mc/BetArea";
import { mapState, mapActions } from "vuex";
export default {
  name: "",
  components: { HistoryLun, ChatArea, BetArea },
  computed: { ...mapState(["hasLogin", "user"]) },
  data() {
    return {
      betShow: true,
      show: false,
      titleTxt: "Quay Lại",
      topShow: false,
      timerLun: null,
      topMinutes: "00",
      topseconds: "00",
      productTop: {
        icon: "",
      },
      topLast: {},
      lunNum: 0, // 轮数
      otherIn: true,
      productId: -1,
      deliteId: -1,
      playType: -1, // 玩法等级
      otherInvesList: [],
      historyList: [],
      conventional: [], //常规玩法
      special: [], //特殊玩法
      digital: [], //数字玩法
      highLow: [], //高低玩法
      isXd: true,
      hallType: -1,
      edList: [],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    console.log(this);
    let option = this.$route.query;
    this.hallType = option.type;
    if (option.product && option.detile) {
      // 接收参数
      this.productId = option.product;
      this.deliteId = option.detile;
      this.playType = option.type;
      /* this.titleTxt = option.title; */
      /* 他人投资 */
      this.othersInvestmentShow();
      this.historyMarketShow();
      this.palyInfoShow();
      /* this.inRecordShow(); */
      this.topDataShow();
      this.updateUser();
    }
    if (option.type + "" === "3") {
      this.edShow();
    }
  },
  beforeDestroy() {
    clearInterval(this.timerLun);
    this.timerLun = null;
  },
  methods: {
    ...mapActions(["GetInfo"]),
    moneyshow() {
      if (this.show == false) {
        this.show = true;
        return;
      } else {
        this.show = false;
        return;
      }
    },
    /* 更新用户信息 */
    updateUser() {
      this.GetInfo()
        .then((res) => {
          console.log("更新成功");
        })
        .catch((err) => {
          console.log("获取用户信息失败");
        });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    /* 跳转交易记录 */
    jumpJy() {
      this.$router.push({
        path: "/orders",
        query: {
          product_id: this.productId,
        },
      });
    },
    /* 打开头部 */
    openTop() {
      this.topShow = true;
    },
    /* 关闭头部 */
    closeTop() {
      this.topShow = false;
    },
    /* 跳转其他 */
    jumpOther(index) {
      let path = "";
      switch (index) {
        case 1:
          path = "/help";
          break;
        case 2:
          path = "/marketMovements";
          break;
        case 3:
          path = "/help";
          break;
        default:
          break;
      }
      this.closeTop();
      this.$router.push({ path });
    },
    onClickRight() {
      console.log("点击了");
    },
    /* 他人投资 */
    onInput(checked) {
      this.otherIn = checked;
      this.closeTop();
      console.log(checked);
    },
    /* 他人投资 */
    othersInvestmentShow() {
      console.log("进来了");
      othersInvestment({
        product_id: this.productId,
        hall_id: this.deliteId,
      }).then((res) => {
        console.log(res);
        this.otherInvesList = res.data;
      });
    },
    /* 查询额度 */
    edShow() {
      getEd({
        hall_id: this.deliteId,
      }).then((res) => {
        this.edList = [];
        res.data.forEach((item) => {
          this.edList.push({
            name: item,
          });
        });
		this.betShow = false;
		this.$nextTick(() => {
			this.betShow = true;
		})
      });
    },
    /* 历史行情 */
    historyMarketShow() {
      historyMarket({
        product_id: this.productId,
      }).then((res) => {
        this.historyList = res.data;
      });
    },
    /* 玩法查询 */
    palyInfoShow() {
      palyInfo({
        product_id: this.productId,
        type: this.playType,
        hall_id: this.deliteId,
      }).then((res) => {
        console.log(res);
        let result = res.data;

        this.conventional = result.changgui; //常规玩法
        this.special = result.teshu; //特殊玩法
        this.digital = result.shuzi; //数字玩法
        this.highLow = result.gaodi; //高低玩法
      });
    },
    jishiqi() {
      var dj = this.lastTime;
      if (dj <= 0) {
        console.log("过期了========================================");
        this.lastTime = "已过期";
        this.topDataShow();
      } else {
        console.log("剩余时间========================================" + dj);
        if (this.isXd && dj < 30) {
          this.isXd = false;
          this.otherIn = false;
          this.$nextTick(() => {
            this.otherIn = true;
          });
        } else if (!this.isXd && dj > 30) {
          this.isXd = true;
          this.otherIn = false;
          this.$nextTick(() => {
            this.otherIn = true;
          });
        }
        var ddf = this.djs(dj); //格式化过后的时间
        this.lastTime = ddf;
        //当前时间减去时间
        dj = dj - 1;
        this.lastTime = dj;
      }
    }, //得到的秒换算成时分秒
    djs(number) {
      var hour = Math.floor(number / 3600), //小时
        minutes = Math.floor((number - hour * 3600) / 60),
        seconds = Math.floor(number - (hour * 3600 + minutes * 60));
      if (minutes < 10) {
        minutes = 0 + "" + minutes;
      }
      if (seconds < 10) {
        seconds = 0 + "" + seconds;
      }
      this.topMinutes = minutes;
      this.topseconds = seconds;
      return minutes + ":" + seconds;
    },
    /* 查询顶部数据 */
    topDataShow() {
      topData({
        product_id: this.productId,
      }).then((res) => {
        console.log(res);
        let result = res.data;
        this.productTop = result.product;
        this.lunNum = result.num;
        this.topLast = result.last;
        console.log(result.end_time + "++++++++++++");
        let endTime =
          new Date(result.end_time.replace(/-/g, "/")).getTime() / 1000;
        let startTime = new Date().getTime() / 1000;
        this.lastTime = endTime - startTime;
        console.log(this.lastTime);
        clearInterval(this.timerLun);
        this.timerLun = setInterval(() => {
          this.jishiqi();
        }, 1000);
      });
    },
    /* 下单成功 */
    successBuy() {
      console.log("下单成功了！");
      this.updateUser();
      this.topDataShow();
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";

.van-nav-bar__right {
  .iconfont {
    font-size: 20px;
  }

  .icon-lishijilu {
    margin-right: 0.2rem;
  }
}

#HeaderTop /deep/.van-switch__node {
  background-color: #fff !important;
}

#Chat {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  // background: #000 url(../../../assets/img/mcbg.png) no-repeat top;
  // background-size: contain;

  background-image: url('../../../assets/new_img/bg2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}
.adddiv {
  width: 94%;
}
#TimeDown {
  width: 100%;

  .wrapper {
    width: 100%;

    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .balance {
        padding-left: 0.3rem;
        position: relative;

        .tomoney {
          background: #201810;
          border-radius: 0.0625rem;
          position: absolute;
          z-index: 9;
          top: 0.7rem;
          left: 0.25rem;
          padding: 0.125rem;
          color: #ffffff;
          font-size: 0.375rem;
        }

        .moeny {
          color: #eecb77;
          font-size: 0.48rem;
          line-height: 0.6rem;

          span {
            display: inline-block;
            color: #fff;
            font-size: 0.32rem;
            line-height: 0.4rem;
            width: 0.4rem;
            height: 0.4rem;
            text-align: center;
          }
        }

        .name {
          font-size: 0.3rem;
          margin-top: 5px;
        }
      }

      .rechargeBtn {
        height: 0.7rem;
        padding: 0 0.15rem;
        border-radius: 0.35rem 0 0 0.35rem;
        background: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);
        font-size: 0.3rem;
        line-height: 0.7rem;
        color: #38373b;
        display: flex;
        align-items: center;

        .iconfont {
          color: #38373b;
          line-height: 0.7rem;
          font-size: 0.4rem;
          margin-right: 0.1rem;
        }
      }
    }

    .TimeAndNumber {
      padding-top: 0.25rem;
      padding-bottom: 0.2rem;
      padding-left: 0.3rem;

      span {
        font-size: 0.3rem;
      }

      strong {
        font-size: 0.3rem;
        color: #eecb77;
        margin-right: 0.3rem;
      }
    }
  }
}

/* 顶部弹窗 */
#HeaderTop .mask {
  position: fixed;
  top: 0.88rem;
  width: 100%;
  height: 100vh;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.4);
}

#HeaderTop .popmenu {
  position: absolute;
  border-radius: 0.1rem;
  background-color: #e2cba7;
  z-index: 4;
  top: 1rem;
  right: 0.2rem;

  li {
    height: 0.5rem;
    line-height: 0.5rem;
    color: #666;
    font-size: 0.3rem;
    text-align: left;
    padding: 0 0.25rem;

    i {
      font-size: 0.34rem;
      width: 0.4rem;
      line-height: 0.5rem;
      display: inline-block;
      text-align: left;
      margin-right: 0.1rem;
    }
  }

  li:last-child {
    border: none;
  }
}
</style>
